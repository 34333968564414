/* eslint-disable global-require,camelcase */

import visa from '@/assets/images/pages/payment/ic-visacard.svg'
import electron from '@/assets/images/pages/payment/ic-electroncard.svg'
import master from '@/assets/images/pages/payment/ic-mastercard.svg'
import amex from '@/assets/images/pages/payment/ic-amexcard.svg'
import discover from '@/assets/images/pages/payment/ic-discovercard.svg'
import jcb from '@/assets/images/pages/payment/ic-jcbcard.svg'
import diners from '@/assets/images/pages/payment/ic-dinnerscard.svg'
import unionpay from '@/assets/images/pages/payment/ic-unionpaycard.svg'
import maestroCard from '@/assets/images/pages/payment/ic-maestrocard.svg'
import dankort from '@/assets/images/pages/payment/ic-dankortcard.svg'
import interpay from '@/assets/images/pages/payment/ic-interpaycard.svg'

import visa_no_color from '@/assets/images/pages/payment/ic-visa-no-color.svg'
import electron_no_color from '@/assets/images/pages/payment/ic-visa-electron-no-color.svg'
import master_no_color from '@/assets/images/pages/payment/ic-master-card-no-color.svg'
import amex_no_color from '@/assets/images/pages/payment/ic-amex-no-color.svg'
import discover_no_color from '@/assets/images/pages/payment/ic-discover-no-color.svg'
import jcb_no_color from '@/assets/images/pages/payment/ic-jcb-no-color.svg'
import diners_no_color from '@/assets/images/pages/payment/ic-dinners-no-color.svg'
import unionpay_no_color from '@/assets/images/pages/payment/ic-union-pay-no-color.svg'
import maestroCard_no_color from '@/assets/images/pages/payment/ic-maestro-no-color.svg'
import dankort_no_color from '@/assets/images/pages/payment/ic-dankort-no-color.svg'
import interpay_no_color from '@/assets/images/pages/payment/ic-interpayment-no-color.svg'

import visa_white from '@/assets/images/pages/payment/ic-visa-white.svg'
import electron_white from '@/assets/images/pages/payment/ic-visa_electron-white.svg'
import master_white from '@/assets/images/pages/payment/ic-masterCard-white.svg'
import amex_white from '@/assets/images/pages/payment/ic-amex-white.svg'
import discover_white from '@/assets/images/pages/payment/ic-discover-white.svg'
import jcb_white from '@/assets/images/pages/payment/ic-JCB-white.svg'
import diners_white from '@/assets/images/pages/payment/ic-dinners-white.svg'
import unionpay_white from '@/assets/images/pages/payment/ic-unionPay-white.svg'
import maestroCard_white from '@/assets/images/pages/payment/ic-maestro-white.svg'
import dankort_white from '@/assets/images/pages/payment/ic-dankort-white.svg'
import interpay_white from '@/assets/images/pages/payment/ic-interpayment-white.svg'
import { createNamespacedHelpers } from 'vuex'
import generalConfigsMixin from '@/mixins/generalConfigsMixin'
import {
  PAYMENT_METHOD,
  CREDIT_CARD_TYPE,
  TAZAPAY_PROHIBITED_COUNTRIES,
  PAYMENT_CHILD_AMOUNT,
  PAYMENT_CHILD_TYPE, LIST_IDEAL_BANK,
} from '@/constants'
import NP from 'number-precision'

const { mapGetters } = createNamespacedHelpers('auth')
const { mapGetters: mapGettersPayment } = createNamespacedHelpers('payment')

export default {
  mixins: [generalConfigsMixin],

  computed: {
    ...mapGetters(['user']),
    ...mapGettersPayment(['listLocalBank']),

    amountItem() {
      const listAmountConvert = PAYMENT_CHILD_AMOUNT.filter(item => item.value > this.minTopUp)
      const currentMinTopUp = {
        value: this.minTopUp,
        type: PAYMENT_CHILD_TYPE.NUMBER,
      }
      return [currentMinTopUp, ...listAmountConvert.slice(0, 4)]
    },

    userCountry() {
      return this.user?.data?.country
    },

    prohibitedCountries() {
      return TAZAPAY_PROHIBITED_COUNTRIES.includes(this.userCountry)
    },

    taxRate() {
      return this.user?.data?.plans?.transferFee?.tax || 0
    },

    listPaymentMethod() {
      return this.paymentMethods.map(item => this.getPaymentMethods(item))
    },

    hasCreditCard() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.CREDITCARD)
    },

    hasPaypal() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PAYPAL)
    },

    hasPayoneer() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PAYONEER)
    },

    hasPayoneerTicket() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PAYONEER_TICKET)
    },

    hasTransferWise() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.TRANSFERWISE)
    },

    hasUSDT() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.USDT)
    },

    hasAirwallex() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.AIRWALLEX)
    },

    hasAirwallexAuto() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.AIRWALLEX_AUTO)
    },

    hasTazapay() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.TAZAPAY)
    },

    hasLianLian() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.LIANLIAN)
    },

    hasLianLianTicket() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.LIANLIAN_TICKET)
    },

    hasPayFast() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PAYFAST)
    },

    hasPingPong() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.PINGPONG_TICKET)
    },

    hasLocalBank() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.LOCALBANK)
    },

    hasLocalBankTicket() {
      return this.generalConfigs?.availablePaymentMethods?.includes(PAYMENT_METHOD.LOCALBANK_TICKET)
    },

    onlyOnePaymentMethod() {
      return this.generalConfigs?.availablePaymentMethods?.length === 1
    },

    paymentInfo() {
      return this.generalConfigs?.paymentInfo
    },

    hasAMId() {
      return this.user?.data?.amId
    },

    topUpInprocessImg() {
      if (this.isEmediaPlatform) {
        return require('@/assets/images/pages/payment/ic-top-up-inprocess-emedia.svg')
      }
      return require('@/assets/images/pages/payment/ic-top-up-inprogress.png')
    },

    feesFormat() {
      return fee => `${(fee * 100).toFixed(2)}%`
    },
  },

  methods: {
    getBrandIcon(brand) {
      switch (brand) {
        case PAYMENT_METHOD.PAYPAL: return require('@/assets/images/pages/payment/ic-paypal.svg')
        case PAYMENT_METHOD.PAYONEER: return require('@/assets/images/pages/payment/ic-payoneer.svg')
        case PAYMENT_METHOD.PINGPONG: return require('@/assets/images/pages/payment/ic-pingpong.svg')
        case PAYMENT_METHOD.USDT: return require('@/assets/images/pages/payment/ic-usdt.svg')
        case PAYMENT_METHOD.USDC: return require('@/assets/images/pages/payment/ic-usdc.svg')
        case PAYMENT_METHOD.BUSD: return require('@/assets/images/pages/payment/ic-bnb.svg')
        case PAYMENT_METHOD.TPBANK: return require('@/assets/images/pages/payment/ic-tpbank.svg')
        case PAYMENT_METHOD.AIRWALLEX: return require('@/assets/images/pages/payment/ic-airwallex.svg')
        case PAYMENT_METHOD.TRANSFERWISE: return require('@/assets/images/pages/payment/ic-transferwise.svg')
        case PAYMENT_METHOD.TAZAPAY: return require('@/assets/images/pages/payment/ic-tazapay.svg')
        case PAYMENT_METHOD.LIANLIAN: return require('@/assets/images/pages/payment/ic-lianlian.svg')
        case PAYMENT_METHOD.PAYFAST: return require('@/assets/images/pages/payment/ic-payfast.jpg')
        case PAYMENT_METHOD.IDEAL: return require('@/assets/images/pages/payment/ic-ideal.svg')
        case PAYMENT_METHOD.BANCONTACT: return require('@/assets/images/pages/payment/ic-bancontact.svg')
        default: return ''
      }
    },

    getCreditCardLogo(brand) {
      switch (brand) {
        case CREDIT_CARD_TYPE.visa: return visa
        case CREDIT_CARD_TYPE.electron: return electron
        case CREDIT_CARD_TYPE.mastercard: return master
        case CREDIT_CARD_TYPE.amex: return amex
        case CREDIT_CARD_TYPE.discover: return discover
        case CREDIT_CARD_TYPE.jcb: return jcb
        case CREDIT_CARD_TYPE.diners: return diners
        case CREDIT_CARD_TYPE.unionpay: return unionpay
        case CREDIT_CARD_TYPE.maestro: return maestroCard
        case CREDIT_CARD_TYPE.dankort: return dankort
        case CREDIT_CARD_TYPE.interpayment: return interpay
        default: return ''
      }
    },

    getNoColorCreditCardLogo(brand) {
      switch (brand) {
        case CREDIT_CARD_TYPE.visa: return visa_no_color
        case CREDIT_CARD_TYPE.electron: return electron_no_color
        case CREDIT_CARD_TYPE.mastercard: return master_no_color
        case CREDIT_CARD_TYPE.amex: return amex_no_color
        case CREDIT_CARD_TYPE.discover: return discover_no_color
        case CREDIT_CARD_TYPE.jcb: return jcb_no_color
        case CREDIT_CARD_TYPE.diners: return diners_no_color
        case CREDIT_CARD_TYPE.unionpay: return unionpay_no_color
        case CREDIT_CARD_TYPE.maestro: return maestroCard_no_color
        case CREDIT_CARD_TYPE.dankort: return dankort_no_color
        case CREDIT_CARD_TYPE.interpayment: return interpay_no_color
        default: return ''
      }
    },

    getWhiteCreditCardLogo(brand) {
      switch (brand) {
        case CREDIT_CARD_TYPE.visa: return visa_white
        case CREDIT_CARD_TYPE.electron: return electron_white
        case CREDIT_CARD_TYPE.mastercard: return master_white
        case CREDIT_CARD_TYPE.amex: return amex_white
        case CREDIT_CARD_TYPE.discover: return discover_white
        case CREDIT_CARD_TYPE.jcb: return jcb_white
        case CREDIT_CARD_TYPE.diners: return diners_white
        case CREDIT_CARD_TYPE.unionpay: return unionpay_white
        case CREDIT_CARD_TYPE.maestro: return maestroCard_white
        case CREDIT_CARD_TYPE.dankort: return dankort_white
        case CREDIT_CARD_TYPE.interpayment: return interpay_white
        default: return ''
      }
    },

    getCreditCardName(brand) {
      switch (brand) {
        case CREDIT_CARD_TYPE.visa: return 'Visa'
        case CREDIT_CARD_TYPE.electron: return 'Electron'
        case CREDIT_CARD_TYPE.mastercard: return 'MasterCard'
        case CREDIT_CARD_TYPE.amex: return 'Amex'
        case CREDIT_CARD_TYPE.discover: return 'Discover'
        case CREDIT_CARD_TYPE.jcb: return 'JCB'
        case CREDIT_CARD_TYPE.diners: return 'Diners'
        case CREDIT_CARD_TYPE.unionpay: return 'Unionpay'
        case CREDIT_CARD_TYPE.maestro: return 'Maestro'
        case CREDIT_CARD_TYPE.dankort: return 'Dankort'
        case CREDIT_CARD_TYPE.interpayment: return 'Interpayment'
        default: return ''
      }
    },

    convertNamePayment(data) {
      if (data === PAYMENT_METHOD.PAYPAL) {
        return 'PayPal'
      }
      if (data === PAYMENT_METHOD.AIRWALLEX) {
        return 'Airwallex'
      }
      if (data === PAYMENT_METHOD.TRANSFERWISE) {
        return 'WISE'
      }
      if (data === PAYMENT_METHOD.LIANLIAN) {
        return 'LianLian'
      }
      if (data === PAYMENT_METHOD.PAYFAST) {
        return 'PayFast'
      }
      return data
    },

    convertIdealBank(bank) {
      switch (bank) {
        case LIST_IDEAL_BANK.ABN_AMRO: return 'ABN AMRO'
        case LIST_IDEAL_BANK.ASN_BANK: return 'ASN Bank'
        case LIST_IDEAL_BANK.BUNQ: return 'Bunq'
        case LIST_IDEAL_BANK.ING: return 'ING'
        case LIST_IDEAL_BANK.KNAB: return 'Knab'
        case LIST_IDEAL_BANK.N26: return 'N26'
        case LIST_IDEAL_BANK.NN: return 'Nationale-Nederlanden'
        case LIST_IDEAL_BANK.RABOBANK: return 'Rabobank'
        case LIST_IDEAL_BANK.REVOLUT: return 'Revolut'
        case LIST_IDEAL_BANK.REGIOBANK: return 'RegioBank'
        case LIST_IDEAL_BANK.SNS_BANK: return 'SNS Bank (De Volksbank)'
        case LIST_IDEAL_BANK.TRIODOS_BANK: return 'Triodos Bank'
        case LIST_IDEAL_BANK.VAN_LANSCHOT: return 'Van Lanschot'
        case LIST_IDEAL_BANK.YOURSAFE: return 'Yoursafe'
        default: return bank
      }
    },

    getPaymentMethods(payment) {
      switch (payment) {
        case PAYMENT_METHOD.PAYPAL:
          return {
            id: 'paypal',
            img: require('@/assets/images/pages/payment/ic-paypal.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-logo-paypal.svg'),
            name: 'PayPal',
            fullName: 'PayPal',
            nameGate: 'paypalGate',
            url: '/payment/add-fund?tab=paypal',
            gateFee: this.feesFormat(this.paymentGateFee.paypalGate),
            methodFee: this.user?.data?.plans?.transferFee?.paypal,
          }
        case PAYMENT_METHOD.PAYONEER:
          return {
            id: 'payoneer',
            img: require('@/assets/images/pages/payment/ic-payoneer-2.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-logo-payoneer.svg'),
            name: 'Payoneer',
            fullName: 'Payoneer',
            nameGate: 'payoneerGate',
            url: '/payment/add-fund?tab=payoneer',
            gateFee: this.feesFormat(this.paymentGateFee.payoneerGate),
            methodFee: this.user?.data?.plans?.transferFee?.payoneer,
          }
        case PAYMENT_METHOD.PAYONEER_TICKET:
          return {
            id: 'payoneer-ticket',
            img: require('@/assets/images/pages/payment/ic-payoneer-2.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-logo-payoneer.svg'),
            name: 'Payoneer',
            fullName: `${this.hasPayoneer ? 'Payoneer ticket' : 'Payoneer'}`,
            nameGate: 'payoneerGate',
            url: '/payment/add-fund?tab=payoneer-ticket',
            gateFee: this.feesFormat(this.paymentGateFee.payoneerTicketGate),
            methodFee: this.user?.data?.plans?.transferFee?.payoneerTicket,
          }
        case PAYMENT_METHOD.USDT:
          return {
            id: 'usdt',
            img: require('@/assets/images/pages/payment/ic-usdt.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-usdt.svg'),
            name: 'USDT',
            fullName: 'USDT',
            nameGate: 'USDTGate',
            url: '/payment/add-fund?tab=usdt',
            gateFee: this.feesFormat(this.paymentGateFee.USDTGate),
            methodFee: this.user?.data?.plans?.transferFee?.USDT,
          }
        case PAYMENT_METHOD.CREDITCARD:
          return {
            id: 'creditcard',
            img: require('@/assets/images/pages/payment/ic-visa.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-stripe.svg'),
            name: 'Credit Card',
            fullName: 'Credit Card',
            nameGate: 'creditCardGate',
            url: '/payment/add-fund?tab=creditcard',
            gateFee: this.feesFormat(this.paymentGateFee.creditCardGate),
            methodFee: this.user?.data?.plans?.transferFee?.creditCard,
          }
        case PAYMENT_METHOD.AIRWALLEX_AUTO:
          return {
            id: 'airwallex',
            img: require('@/assets/images/pages/payment/ic-airwallex.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-logo-airwallex.svg'),
            name: 'Airwallex',
            fullName: 'Airwallex',
            nameGate: 'airWallexAutoGate',
            url: '/payment/add-fund?tab=airwallex',
            gateFee: this.feesFormat(this.paymentGateFee.airWallexGate),
            methodFee: this.user?.data?.plans?.transferFee?.airWallex,
          }
        case PAYMENT_METHOD.TRANSFERWISE:
          return {
            id: 'wise',
            img: require('@/assets/images/pages/payment/ic-transferwise.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-logo-wise.svg'),
            name: 'Wise',
            fullName: 'Wise',
            nameGate: 'transferWiseGate',
            url: '/payment/add-fund?tab=wise',
            gateFee: this.feesFormat(this.paymentGateFee.transferWiseGate),
            methodFee: this.user?.data?.plans?.transferFee?.transferWise,
          }
        case PAYMENT_METHOD.TAZAPAY:
          return {
            id: 'tazapay',
            img: require('@/assets/images/pages/payment/ic-tazapay.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-logo-tazapay.svg'),
            name: 'Tazapay',
            fullName: 'Tazapay',
            nameGate: 'tazapayGate',
            url: '/payment/add-fund?tab=tazapay',
            gateFee: this.feesFormat(this.paymentGateFee.tazapayGate),
            methodFee: this.user?.data?.plans?.transferFee?.tazapay,
          }
        case PAYMENT_METHOD.LIANLIAN:
          return {
            id: 'lianlian',
            img: require('@/assets/images/pages/payment/ic-lianlian.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-lianlian.svg'),
            name: 'LianLian',
            fullName: 'LianLian',
            nameGate: 'lianlianGate',
            url: '/payment/add-fund?tab=lianlian',
            gateFee: this.feesFormat(this.paymentGateFee.lianLianTicketGate),
            methodFee: this.user?.data?.plans?.transferFee?.lianLianTicket,
          }
        case PAYMENT_METHOD.PAYFAST:
          return {
            id: 'payfast',
            img: require('@/assets/images/pages/payment/ic-payfast.jpg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-logo-payfast.svg'),
            name: 'PayFast',
            fullName: 'PayFast',
            nameGate: 'payfastGate',
            url: '/payment/add-fund?tab=payfast',
            gateFee: this.feesFormat(this.paymentGateFee.payfastGate),
            methodFee: this.user?.data?.plans?.transferFee?.payfast,
          }
        case PAYMENT_METHOD.LOCALBANK:
          return {
            id: 'local-bank',
            img: require('@/assets/images/pages/payment/ic-local-bank.png'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-logo-localbank.svg'),
            name: 'Local Bank',
            fullName: 'Local Bank',
            nameGate: 'localBankGate',
            url: '/payment/add-fund?tab=local-bank',
            gateFee: this.feesFormat(this.paymentGateFee.localBankGate),
            methodFee: this.user?.data?.plans?.transferFee?.localBank,
          }
        case PAYMENT_METHOD.LOCALBANK_TICKET:
          return {
            id: 'local-bank-ticket',
            img: require('@/assets/images/pages/payment/ic-local-bank.png'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-local-bank.png'),
            name: 'Local Bank',
            fullName: 'Local Bank',
            nameGate: 'localBankGate',
            url: '/payment/add-fund?tab=local-bank-manual',
            gateFee: this.feesFormat(this.paymentGateFee.localBankTicketGate),
            methodFee: this.user?.data?.plans?.transferFee?.localBank,
          }
        case PAYMENT_METHOD.IDEAL:
          return {
            id: 'ideal',
            img: require('@/assets/images/pages/payment/ic-ideal.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-ideal.svg'),
            name: 'iDEAL',
            fullName: 'iDEAL',
            nameGate: 'idealFee',
            url: '/payment/add-fund?tab=ideal',
            gateFee: this.feesFormat(this.paymentGateFee.idealGate),
            methodFee: this.user?.data?.plans?.transferFee?.ideal,
          }
        case PAYMENT_METHOD.BANCONTACT:
          return {
            id: 'bancontact',
            img: require('@/assets/images/pages/payment/ic-bancontact.svg'),
            LogoFullPayment: require('@/assets/images/pages/payment/ic-bancontact.svg'),
            name: 'Bancontact',
            fullName: 'Bancontact',
            nameGate: 'bancontactGate',
            url: '/payment/add-fund?tab=bancontact',
            gateFee: this.feesFormat(this.paymentGateFee.bancontactGate),
            methodFee: this.user?.data?.plans?.transferFee?.bancontact,
          }
        default: return {
          id: 'creditcard',
          img: require('@/assets/images/pages/payment/ic-visa.svg'),
          LogoFullPayment: require('@/assets/images/pages/payment/ic-visa.svg'),
          name: 'Credit Card',
          fullName: 'Credit Card',
          nameGate: 'creditCardGate',
          url: '/payment/add-fund?tab=creditcard',
          gateFee: this.feesFormat(this.paymentGateFee.creditCardGate),
          methodFee: this.user?.data?.plans?.transferFee?.creditCard,
        }
      }
    },

    convertUSDtoVND(amount) {
      const rate = this.USDToVNDRate
      return Math.ceil(NP.times(amount || 0, rate))
    },

    convertUSDtoPKR(amount) {
      const rate = this.USDToPKRRate
      return amount * rate
    },

    convertUSDtoEUR(amount) {
      const rate = this.USDToEURRate
      return Math.round(amount * rate * 100) / 100
    },

    convertMoney(amount, currency) {
      switch (currency) {
        case 'VND': return `${this.convertUSDtoVND(amount)} VNĐ`
        case 'EUR': return `${this.convertUSDtoEUR(amount)} EUR`
        case 'PKR': return `${this.convertUSDtoPKR(amount)} PKR`
        default: return `${amount} USD`
      }
    },

    // Tracking top-up
    trackingTopUp(amount, localUserId, email, phoneNumber) {
      const dataLayer = {
        event: 'topup_over500',
        amount_500: amount,
        user_phoneNumber: phoneNumber,
        user_email: this.userEmail,
        user_id: this.userEmail,
        userId: this.userEmail,
        localUserId,
      }
      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push(dataLayer)

      // window.ttq.track('topup_over500', {
      //   value: amount,
      //   currency: 'USD',
      //   email,
      //   phoneNumber,
      // })
    },
  },
}
